@import "../../variables.scss";
.tickets_container{
    padding: 20px;

    .verify_post{
        position: absolute;
        top: 50%;
        left: 0;
        text-align: center;
        transform: translate(0, -50%);
    
        p{
          font-weight: 400;
          font-size: 24px;
          color: #E4ECFE;
          font-family: 'Genos';
          padding: 0 20px;
        }
      }
      
    .checkout_title{
        font-family: 'Genos';
        font-weight: 500;
        font-size: 40px;
        letter-spacing: -0.457589px;
        color: #E4ECFE;
    }

    .checkout_state{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        hr{
            transform: rotate(180deg);
            width: 60%;
            border: 1px solid #747577;
        }

        .ticket_state, .contact_state{
            width: 20%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            padding: 20px 0;
            input[type=radio]{
                appearance: none;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-clip: content-box;
                border: 2px solid rgba(255, 252, 229, 1);
                background-color: rgba(255, 252, 229, 1);
            }
            input[type="radio"]:checked{
                background-color: rgb(31, 31, 192);
                border: 2px solid #000;
                color: #E4ECFE;
            }
label{
    color: #E4ECFE;
    font-family: 'Genos';
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.457589px;
    margin-left: 3px;
}
            input:checked + label{
                color: rgb(31, 31, 192);
    
            }
        }
    }

    .checkout_top_container,  .ticket_contact_heading{
        display: flex;
        align-items: center;
        padding: 10px 0;
        .back_button{
            background-color: rgb(31, 31, 192);
            color: #E4ECFE;
            width: 25px;
            height: 25px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            font-size: 18px;
        }

        h1{
            font-family: 'Genos';
            font-weight: bold;
            font-size: 40px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.457589px;
            color: #E4ECFE;
        }
    }

    .checkout_ticket{
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        align-items: center;
        h4{
            font-weight: 600;
font-size: 18px;
line-height: 13px;
letter-spacing: -0.457589px;
color: #E4ECFE;
        }
        .ticket_quantity{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 30%;
            button{
                background-color: rgb(31, 31, 192);
                color: #E4ECFE;
                width: 25px;
                height: 25px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }
            p{
                font-weight: 600;
                font-size: 28px;
                color: #E4ECFE;
                font-family: 'Genos';
            }
        }
    }

    .checkout_ticket_info{
        h5{
            font-family: 'Genos';
            font-weight: bold;
            font-size: 30px;
            letter-spacing: -0.457589px;
            color: rgb(31, 31, 192);
        }
        p{
            font-weight: 600;
            font-size: 20px;
            color: #E4ECFE;
            font-family: 'Genos';
            margin: 20px 0;
        }
    }
    .bottom_ticket{
        position: absolute;
        bottom: 10%;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 30px;
        .buy_background{
        background-color: rgb(31, 31, 192);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px;
        border-radius: 20px;

        p{
            font-weight: 600;
            font-size: 32px;
            color: #E4ECFE;
            font-family: 'Genos';
        }
        button{
padding: 10px 15px;
width: 80px;
text-align: center;
width: 100px;
border: 1px solid #535766;
font-family: 'Genos';
font-size: 18px;
color: rgb(31, 31, 192);
background-color: #E4ECFE;
border-radius: 10px;
        }
        }
    }
    .ticketFormContainer{
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .inputContainer{
            width: 100%;
            display: flex;
            align-items: center;
            padding: 5px 0;
            border-bottom: 2px solid rgba(255, 255, 255, 0.31);
        label{
            text-transform: uppercase;
            margin-right: 20px;
font-family: 'Genos';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 14px;

leading-trim: both;
text-edge: cap;
letter-spacing: -0.5px;
color: rgb(31, 31, 192);
 }
 input{
    background-color: transparent;
    outline: 0;
   border: none;
    width: 70%;
font-family: 'Genos';
font-style: normal;
font-weight: 400;
font-size: 33.1px;
line-height: 14px;
leading-trim: both;
text-edge: cap;
display: flex;
align-items: center;
letter-spacing: -0.5px;
color: #E4ECFE;
 }
        }
    }
}