@import "./Styles/variables.scss";
@import "./Styles/error.scss";
@import "./Styles/header.scss";
@import "./Styles/menu.scss";
@import "./Styles/Pages/onboarding.scss";
@import "./Styles/Pages/login.scss";
@import "./Styles/Pages/signup.scss";
@import "./Styles/Pages/otp.scss";
@import "./Styles/Pages/welcome.scss";
@import "./Styles/Pages/forgotpwd.scss";
@import "./Styles/Pages/HOMEPAGE/homepage.scss";
@import "./Styles/Pages/HOMEPAGE/event.scss";
@import "./Styles/Pages/HOMEPAGE/buy_tickets_modal.scss";
@import "./Styles/Pages/HOMEPAGE/buy_tickets.scss";
@import "./Styles/Pages/HOMEPAGE/view_event.scss";
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
background-repeat: no-repeat;
height: 100%;
background: linear-gradient(180deg, #000000 0%, #050711 38.5%, #060C22 100%);
background-attachment: fixed;
}
p{
  color: #fff;
  font-size: 24px;
}
.hour{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-hourglass {
  /* change color here */
  color: #E4ECFE;
}
.lds-hourglass,
.lds-hourglass:after {
  box-sizing: border-box;
}
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}