@import "../../variables.scss";
/* The switch - the box around the slider */
.create_event_container{
  width: 100%;
  padding: 20px;

  .verify_post{
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    transform: translate(0, -50%);

    p{
      font-weight: 400;
      font-size: 24px;
      color: #E4ECFE;
      font-family: 'Genos';
      padding: 0 20px;
    }
  }

  h1{
    font-family: 'Genos';
    font-weight: bold;
    font-size: 40px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.457589px;
    color: #E4ECFE;
  }

  .event_title_container, .event_description_container, .event_image_banner, .event_date_container
  , .event_location_container, .event_price_container
  ,.event_capacity_container, .category, .event_contact_container{
    padding: 10px 0;
    h5{
      font-weight: 600;
      font-size: 28px;
      color: #fff;
      font-family: 'Genos';
      margin-bottom: 15px;
      font-weight: bold;
    }


    p{
      font-weight: 400;
      font-size: 20px;
      color: #E4ECFE;
      font-family: 'Genos';
    }
    input{
      width: 100%;
      padding: 10px 20px;
      margin: 10px 0;
      border-radius: 20px;
    }
  }
  .event_location_container{
    .location_buttons{
      width: 100%;
      display: flex;
      button{
        background-color: rgb(31, 31, 192);
        padding: 10px;
        margin-right: 20px;
        color: #E4ECFE;
        font-weight: 400;
        font-size: 20px;
        color: #E4ECFE;
        font-family: 'Genos';
        display: flex;
        align-items: center;

        svg{
          margin-right: 5px;
        }
      }
    }
  }
  .button_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button{
      max-width: 300px;
      background-color: rgb(31, 31, 192);
      padding: 15px;
      font-size: 40px;
        color: #E4ECFE;
        font-family: 'Genos';
        border-radius: 20px;
    }
  }

}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.toggle_text{
  font-weight: 400;
  display: inline-block;
  font-size: 20px;
  color: #E4ECFE;
  font-family: 'Genos';
  width: 100%;
}