  @import "./variables.scss";
  .menu-container{
    width: 100%;
    height: 50px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    margin-bottom: 20px;
    position: absolute;
    bottom: 0;
    .menu{
        display: flex;
        width: 90%;
        height: 100%;
        list-style: none;
        justify-content: space-evenly;
        align-items: center;
        padding: 25px;
background: rgba(62, 67, 78, 0.18);
border-radius: 47px;

.menu-list{
  color: #E4ECFE;
  font-size: 18px;
  width: 100%;

  svg{
    color: #E4ECFE;
    font-size: 18px;
    background-color: #E4ECFE;
  }
}
    }
  }