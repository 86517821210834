@import "./variables.scss";
.not-found-container{
    width: 100%;
    display: flex;
    min-height: 70vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    .error_img_container{
        width: 100%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    p{
        font-weight: 400;
        font-size: 24px;
        color: #E4ECFE;
        font-family: 'Genos';
        padding: 0 20px;
      }
}